import React from 'react'
import styled from 'styled-components'
import Uploader from '../BidChat/Uploader'
import EditIcon from '@material-ui/icons/Edit'

interface Props {
	children?: React.ReactNode
	style?: React.CSSProperties
	onUpload?: (urls: string[]) => void
	disabled?: boolean
	maxNumAllowed?: number
}

const UploadOverlay = ({ children, style, onUpload, disabled, maxNumAllowed }: Props) => {
	const [open, setOpen] = React.useState(false)
	const openNow = process.env.NODD_ENV !== 'production' ? () => {
		setOpen(true)
	}
		: () => setOpen(true)

	return (
		<>
			<Outer disabled={disabled} style={style} onClick={!disabled && openNow}>
				{children}
				{!disabled && (
					<Overlay>
						<EditIcon />
					</Overlay>
				)}
			</Outer>
			<Uploader open={open} imagesOnly onClose={() => setOpen(false)} onSuccess={(res) => onUpload && onUpload(res.map(r => r.url))} maxNumAllowed={maxNumAllowed} />
		</>
	)
}

export default UploadOverlay

const Overlay = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,.6);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-out;
`

const Outer = styled.div<{ disabled?: boolean }>`
	position: relative;
	overflow: hidden;
	cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};
	&:hover > ${Overlay} {
		opacity: 1;
	}
`
