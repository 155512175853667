import * as React from 'react';
import { useMutation } from "@apollo/react-hooks"
import { Paper } from "@material-ui/core"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import { SET_NOTIFICATION_AS_READ } from "../../apollo/mutations"
import { Link } from "gatsby"
import { useNotificationsQuery } from "../../apollo/generated"
import * as moment from 'moment'
import Loading from '../ui/Loading';

const NotificationsList = () => {
  const {data, loading} = useNotificationsQuery()
  const [updateNotification] = useMutation(SET_NOTIFICATION_AS_READ);
  if (loading) return <Loading />
  const { notifications } = data.user;
  const markAsRead = (notification) => updateNotification({
    variables: {
      id: notification.id,
    },
    refetchQueries: ['NotificationCount', 'Notifications'],
  })
  return (
    <>
      <Title variant={"h5"}>Your Notifications</Title>
      {notifications.length > 0 ?notifications.map(notification => {
        return (
          <Message key={notification.id} read={notification.read}>
            <div>
              <NotificationTitle>
                {notification.title} <NotificationDate>{moment(notification.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</NotificationDate>
              </NotificationTitle>
              <Typography>
                {notification.message}
                <StyledLink to={notification.link} onClick={() => markAsRead(notification)}>Click Here</StyledLink>
              </Typography>
            </div>
            {!notification.read ? <MarkAsRead onClick={() => updateNotification({
              variables: {
                id: notification.id,
              },
              refetchQueries: ['NotificationCount', 'Notifications'],
            })} /> : null}
          </Message>
        )
      }): <NoNotifications>No notifications to display</NoNotifications>}
    </>
  )
}

const StyledLink = styled(Link)`
  color: black;
  margin-left: 10px;
`
const Title = styled(Typography)`
  margin: 20px;
  color: white;
`;

const Message = styled(Paper) <{ read?: boolean }>`
  display: flex;
  color: black;
  margin: 20px;
  padding: 20px;
  background-color: ${props => (props.read ? "lightgray" : "white")};
`

const MarkAsRead = styled(CheckCircleOutline)`
  margin-left: auto;
`;

const NoNotifications = styled.div`
  margin-left: 20px;
`

const NotificationTitle = styled(Typography)`
 font-weight: bold;
`

const NotificationDate = styled.span`
  color: #a4a4a4;
  font-size: 13px;
  margin-left: 10px;
`

export default NotificationsList
