import * as React from 'react'
import { navigate } from 'gatsby'
import JobSidebar from '../components/jobSidebar'
import NotificationsList from '../components/notifications/notificationsList'
import ContractorSidebar from '../components/contractorSidebar'
import { useUserRoleQuery, UserRole } from '../apollo/generated'
import Loading from '../components/ui/Loading';

const NotificationsPage = () => {

	const { data, loading, error } = useUserRoleQuery();

	if (loading) { return <Loading /> }
	if (error) { return <div>Error: {error.message}</div>; }
	if (!data || !data.user || !data.user.id) {
		navigate('/');
		return null;
	}

	const { user: { role } } = data;

	if (role === UserRole.Homeowner) {
		return (
			<JobSidebar>
				<NotificationsList />
			</JobSidebar>
		)
	} else if (role === UserRole.Contractor) {
		return (
			<ContractorSidebar>
				<NotificationsList />
			</ContractorSidebar>
		);
	} else {
		return <NotificationsList />
	}
}

export default NotificationsPage
